.popup__main {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

    .popup__show {
        position: relative;
        width: 80%;
        min-height: 50vh;
        max-height: 80vh;
        background: white;
        border: 1px solid #c0c0c0;
        border-radius: 2rem;
        padding: 10px;
        overflow: scroll;
        box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    }
}

@media screen and (max-width: 767px) {
    .popup__main {
        .popup__show {
            width: 100%;
            max-height: 60vh;
        }
    }
}

.map-container {
    height: 400px;
}
