.apply-finished-box {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.custom-th {
    white-space: nowrap;
}

.text-sm {
    font-size: 12px;
}
