.loading-page {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: rgba(0, 0, 0, 0.5);
    z-index: 99999;
    scroll-behavior: smooth;
}
