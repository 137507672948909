.header-picture {
    display: flex;
    justify-content: center;
    align-items: center;
}

.apply-status {
    width: 100px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px 0 0 15px;
}

.display-finsihed {
    display: flex;
    justify-content: flex-end;
}

.apply-finished {
    width: 100px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 15px 15px 0;
}
