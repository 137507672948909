.contact-us-upper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.contact-us-div {
    width: 300px;
    border-bottom: 2px solid #008cba;
}

.contact-us-header {
    font-weight: 600;
    font-size: 30px;
    width: 100%;
    text-align: center;
    padding: 20px 0;
    color: #008cba;
}

.contact-us-container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
}
.contact-us-icon-box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;

    padding: 10px;
}

.contact-us-icon {
    width: 40px;
    height: 40px;
    background: #008cba;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}
