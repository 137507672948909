* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.main__page {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.main__body {
    flex: 1;
    margin-top: 50px;
    margin-bottom: 50px;
    // display: flex;
    // width: 100%;
    // height: 100%;
    // justify-content: center;
    // align-items: flex-start;
}

.ad__pointer {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid white;
    background: rgb(52, 152, 219);
    opacity: 0.5;
    color: white;
    transition: all 0.5s ease-in-out;
    &:hover {
        opacity: 1;
    }
}

.ad__up__down {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border: 1px solid white;
    border-radius: 50%;
    background: rgb(124, 136, 139);
    opacity: 0.5;
    color: white;
    transition: all 0.5s ease-in-out;
    &:hover {
        opacity: 1;
    }
}

.ad__animation {
    transition: all 1s ease;
}

ul {
    list-style-type: none;
}
